.card__see-more,
.card__see-less {
    @include flex($justifyContent: center, $alignItems: center);
    @include absolute($bottom: 10px, $right: 10px);
    @include size(26px);
    background-color: $color-second;
    border-radius: $border-radius--round;
    cursor: pointer;
    transition: background-color $duration $timing;

    &:hover, &:focus {
        background-color: $color-dark;

        &::before {
            color: $color-white;
        }
    }

    &::before {
        color: $color-text;
        font: normal 1rem/1 'dashicons';
        transition: color $duration $timing;
  }
}

.card__see-more {
    &::before {
        content: "\f543";
    }
}

.card__see-less {
    &::before {
        content: "\f460";
    }
}

.card__more {
    @include flex($direction: column, $justifyContent: center);
    @include absolute($top: 0, $left: 0);
    @include size(100%);
    box-sizing: border-box;
    padding: 20px;
    background-color: $color-white;
    opacity: 0;
    pointer-events: none;
    transition: opacity .45s $timing;

    &.see-more {
        opacity: 1;
        pointer-events: auto;
    }
}