.home {

    &__title {
        font-size: $font-size--1;
        font-weight: $font-weight-normal;
        margin: 0 0 40px;

        &--fw {
            font-weight: $font-weight-black;
        }
    }

    &__button {
        position: relative;
        z-index: 0;
        margin: 25px 0 0 0;
        color: $color-white;
        background-color: $color-dark;
        padding: 15px 10px 15px 25px;
        border: 0;
        overflow: hidden;

        &:hover, &:focus {
            &:after {
                @include size(60px);
                right: -25px
            }

            svg {
                fill: $color-text;
            }
        }

        svg {
            position: relative;
            z-index: 1;
            @include size(20px);
            margin-left: 10px;
            fill: $color-white;
            transition: fill $duration $timing;
        }

        &:after {
            content: "";
            z-index: 0;
            @include absolute($top: 0, $right: -9px, $bottom: 0);
            @include size(17px);
            margin: auto 0;
            background-color: $color-second;
            border-radius: $border-radius--round;
            transition: {
                property: width, height, right;
                duration: $duration;
                timing-function: $timing;
            }
        }
    }

    &__navigation {
        position: relative;
        z-index: 1;
        @include flex($direction: column, $justifyContent: space-between);
        @include size(40px, 95px);

        .swiper-button {
            position: initial;
            @include size(40px);
            margin: 0;
            padding: 0;
            border: 0;
            background-color: $color-dark;
            border-radius: $border-radius--round;

            svg {
                fill: $color-white;
                transition: fill $duration $timing;
            }

            &.swiper-button-disabled {
                opacity: .4;
                background-color: $color-gray;
                pointer-events: none;
            }

            &.swiper-button-prev, &.swiper-button-next {
                &:after {
                    content: none;
                }
            }

            &:hover, &:focus {
                background-color: $color-second;

                svg {
                    fill: $color-text;
                }
            }
        }
    }
}

//======================================================================================================
// Search + Access
//======================================================================================================

.searchaccess {

    &__section {
        position: relative;
        z-index: 1;
        @include size(100%, 420px);
        background-color: $color-third;
        overflow: hidden;

        .forme-header {
            z-index: 2;
            @include absolute($top: 0, $right: calc(50% - 220px), $bottom: 0,);
            @include size(140px, 420px);
            fill: $color-third;
        }

        .points-header {
            z-index: 2;
            @include absolute($top: -40px, $left: calc(50% - 635px));
            @include size(1430px, 500px);
            clip-path: polygon(0 40px, 100% 40px, 100% 460px, 0% 460px);
            fill: $color-main;
            opacity: .5;
        }

        &:has(> img)::before {
            content: "";
            @include absolute($top: 0, $bottom: 0, $left: 0);
            @include size(calc(50% + 220px), 100%);
            z-index: 1;
            margin: auto;
            background-color: #0000004D;
        }
    }

    & img {
        position: relative;
        z-index: 0;
        display: block;
        @include size(calc(50% + 220px), 100%);
        object-fit: cover;
        object-position: top;
    }

    &__container {
        @include flex($alignItems: center, $justifyContent: space-between);
        @include absolute($top: 0, $right: 0, $bottom: 0, $left: 0);
        z-index: 2;
        margin: auto;
    }

    .searchbar-home {
        padding-bottom: 45px;
        
        &__title {
            color: $color-white;
            font-size: toRem(30);
            line-height: toRem(36);
            font-family: $font-family--heading;
            font-weight: $font-weight-normal;
            margin:  0 0 20px;
            filter: drop-shadow(0px 0px 10px 0px #0000008C);


            span {
                font-weight: $font-weight-extrabold;
            }

            &--noimg {
                color: $color-text;
                filter: unset;

            }
        }

        &__bar {
            position: relative;
            
            .search-container {
                position: relative;
                z-index: 1;

                #searchInput {
                    @include size(500px, 50px);
                    padding: 15px 50px 15px 15px;
                    border-radius: 10px;
                    border: 0;
                    font-size: 14px;
                }

                &.search-container-open {
                    #searchInput {
                        border-radius: 10px 10px 0 0;
                    }
                    .search-svg {
                        border-radius: 0 10px 0 0;
                    }
                }
    
                .search-svg {
                    @include size(50px);
                    border-radius: 0 10px 10px 0;
                    overflow: hidden;
                    background-color: unset;

                    &:hover, &:focus {
                        background-color: unset;
                        
                        &:before {
                            background-color: $color-dark;
                        }

                        & svg {
                            fill: $color-white;
                        }
                    }
    
                    &:before {
                        content: "";
                        @include absolute($top: -5px, $left: 0);
                        @include size(60px);
                        background-color: $color-main;
                        border-radius: $border-radius--round 0 0 $border-radius--round;
                        transition: {
                            property: background-color;
                            duration: $duration;
                            timing-function: $timing;
                        }
                    }
    
                    svg {
                        @include size(30px);
                        z-index: 1;
                        fill: $color-text;
                        transition: {
                            property: fill;
                            duration: $duration;
                            timing-function: $timing;
                        }
                    }
                }
            }

            .searchResult {
                @include absolute($top: 40px, $right: 0, $left:0);
                padding-top: 38px;
                border-radius: 0 0 10px 10px;
                z-index: 0;
                box-shadow: $shadow;
                overflow: hidden;

                &.showX {

                    li {
                        display: none;

                        & .resultTitle, &.show-all a {
                            font-size: toRem(14);
                            padding: 10px 15px;
                        }

                        & .resultCPT  {
                            font-size: toRem(14);
                        }

                        &:nth-child(1), &:nth-child(2), &.show-all {
                            display: block;
                        }
                    }
                }
            }
        }
    }

    .access {

        &__list {
            @include size(540px, 275px);
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            grid-gap: 15px;
        }

        &__link {
            position: relative;
            @include flex($direction: column, $alignItems: center, $justifyContent: center);
            @include size(100%);
            color: $color-text;
            text-align: center;
            font-size: $font-size--text;
            line-height: toRem(20);
            font-weight: $font-weight-semibold;
            background-color: $color-white;
            border: $border-width $border-style $color-bg--transparent;
            border-radius: $border-radius;
            overflow: hidden;
            transition: border-color $duration $timing;

            svg {
                @include size(60px, 50px);
                fill: $color-dark;
            }

            &:before {
                content: "";
                @include absolute($right: 0, $bottom: -25px, $left: 0);
                @include size(25px);
                background-color: $color-main;
                border-radius: $border-radius--round;
                margin: 0 auto;
                transition: {
                    property: bottom;
                    duration: $duration;
                    timing-function: $timing;
                }
            }

            &:hover, &:focus, &:focus-within {
                border-color: $color-main;

                &:before {
                    bottom: -13px;
                }
            }
        }
    }
}

//======================================================================================================
// Posts
//======================================================================================================
.posts {

    &__section {
        margin-top: 90px;
    }

    &__title {
        padding: 0;

        &:before {
            content: none;
        }
    }

    &__swiper-container {
        @include flex( $alignItems: center, $justifyContent: space-between);
    }

    &__list-small {
        @include size(1100px, 180px);

        .swiper-container {
            height: 180px;
        }

        .posts {
            &__item {
                &:hover,&:focus, &:focus-within {
                    
                    .posts__item__content {
                        svg {
                            transform: rotate(90deg);
                            padding: 15px 15px 10px 10px;
                            background-color: $color-second;
                        }
                    }
                }
            }
        }
    }

    &__item {
        display: inline-block;
        @include size(100%, 180px);
        border-radius: $border-radius;
        background-color: $color-fourth;
        overflow: hidden;

        &--0 {
            position: relative;
            @include flex($alignItems: center, $justifyContent: space-between);
            @include size(100%, 345px);
            background-color: $color-white;
            border-radius: $border-radius;
            box-shadow: $shadow;
            margin-bottom: 30px;
            padding-right: 40px;
            overflow: hidden;

            &:before {
                content: "";
                @include absolute($right: -20px, $bottom: -20px);
                @include size(0);
                background-color: $color-second;
                border-radius: $border-radius--round;
                transition: {
                    property: height, width;
                    duration: $duration;
                    timing-function: $timing;
                }
            }

            .posts {
                &__item {
                    &__content {
                        @include size(445px, auto);
                        margin: 0 45px 0 0;
                        padding: 0;
                        transition: margin $duration $timing;
                    }

                    &__title {
                        position: relative;
                        font-size: toRem(34);
                        line-height: toRem(38);
                        padding-bottom: 20px;
                        margin-bottom: 20px;
                        
                        &:before {
                            content: "";
                            @include absolute($bottom: 0, $left: 0);
                            @include size(60px, 4px);
                            background-color: $color-second;
                            transition: width $duration $timing;
                        }
                    }

                    &__intro {
                        font-size: $font-size--text;
                        line-height: toRem(24);
                        color: $color-text;
                    }
                }
            }

            &:hover,&:focus, &:focus-within {

                &:before {
                    @include size(75px);
                }

                .posts {
                    &__item {

                        &__content {
                            margin: 0 30px 0 15px;
                        }

                        &__title {
                            &:before {
                                width: 70px;
                            }
                        }

                        &__container-img {
                            img {
                                transform: scale(1.05);
                            }
                        }
                    }

                    &__plus {
                        transform: rotate(90deg);
                    }
                }
            }
        }

        &__container-img {
            position: relative;
            @include size(630px, 100%);
            overflow: hidden;

            svg {
                @include absolute($top: 0, $right: 0, $bottom: 0);
                @include size(62px, 100%);
                fill: $color-white;
            }

            &:after {
                content: "";
                @include size(0, 100%);
                @include absolute($top: 0, $right: 0, $bottom: 0);
                background-color: $color-white;
            }

            img {
                display: block;
                @include size(100%);
                object-fit: cover;
                object-position: top;
                transform: scale(1);
                transition: transform $duration $timing;
            }

            &--notimg {
                background: url($urlSpriteImage + '/base-icons/posts-icons/posts.svg') no-repeat $color-bg--image;
                background-position: center;
            }
        }

        &__content {
            position: relative;
            @include size(347px,  100%);
            @include flex($direction: column, $justifyContent: center);
            box-sizing: border-box;
            padding: 0 30px 20px;

            svg {
                @include absolute($right: -9px, $bottom: -9px);
                @include size(50px);
                padding: 10px 15px 15px 10px;
                background-color: $color-white;
                border-radius: $border-radius--round;
                box-sizing: border-box;
                transform: rotate(0);
                transition: {
                    property: transform, padding, background-color;
                    duration: $duration;
                    timing-function: $timing;
                }
            }
        }

        &__title {
            font-size: toRem(22);
            line-height: toRem(28);
            font-weight: $font-weight-bold;
            color: $color-text;
            margin: 0;
        }
    }

    &__plus {
        @include absolute($right: 10px, $bottom: 10px);
        @include size(26px);
        transform: rotate(0);
        transition: transform $duration $timing;
    }

    &__button {
        margin-top: 40px;
    }
}

//======================================================================================================
// Events
//======================================================================================================

.events {

    &__section {
        position: relative;
        z-index: 0;
        margin-top: 160px;
        padding-bottom: 70px;

        &:before {
            content: "";
            z-index: -1;
            @include absolute($right: 0, $bottom: 0, $left: 0);
            @include size(100%, 410px);
            background-color: $color-third;
            pointer-events: none;
        }
    }
    
    &__title {
        padding: 0;

        &:before {
            content: none;
        }
    }

    &__header {
        @include flex($alignItems: center, $justifyContent: space-between);
    }

    &__navigation {
        @include size(95px, 40px);
        flex-direction: row;

        &.js-eventsNav--no-slider {
            display: none;
        }

        & .swiper-button {
            &:hover, &:focus {
                background-color: $color-main;
            }
        }
    }

    &__container {
        overflow: hidden;
    }

    .swiper-container {
        overflow: unset;
    }

    .swiper-slide {
        @include size(370px);
    }

    &__item {
        position: relative;
        display: inline-block;
        @include size(370px);
        background-color: $color-white;
        border-radius: $border-radius;
        box-shadow: $shadow;
        overflow: hidden;

        &__container-img {
            position: relative;
            @include size(370px, 270px);
            overflow: hidden;

            img {
                @include size(100%);
                object-fit: cover;
                object-position: top;
                transform: scale(1);
                transition: transform $duration $timing;
            }

            svg {
                @include absolute( $right: 0, $bottom: 0, $left: 0);
                @include size(370px, 40px);
                fill: $color-white;
            }

            &--notimg {
                background: url($urlSpriteImage + '/events-icons/events.svg') no-repeat $color-bg--image;
                background-position: center;
            }
        }

        &:hover, &:focus {
            .events__item {

                // &__content {
                //     padding-top: 5px;
                // }

                &__title {
                    color: $color-text;
                }
    
                &__plus {
                    transform: rotate(90deg);
                    padding: 15px 15px 10px 10px;
                    background-color: $color-main;
                }

                &__container-img {
                    img {
                        transform: scale(1.05);
                    }
                }
            }
        }

        &__content {
            padding: 0 25px;
            transition: padding-top $duration $timing;
        }

        &__title {
            margin: 20px 0 0;
            font-size: toRem(22);
            line-height: toRem(28);
            color: $color-text;
        }

        &__chevron {
            @include size(20px);
            fill: $color-dark;
        }

        &__plus {
            @include absolute($right: -9px, $bottom: -9px);
            @include size(50px);
            padding: 10px 15px 15px 10px;
            background-color: $color-white;
            border-radius: $border-radius--round;
            box-sizing: border-box;
            transform: rotate(0);
            transition: {
                property: transform, padding, background-color;
                duration: $duration;
                timing-function: $timing;
            }
        }

        &__date {
            position: relative;
            z-index: 1;
            @include flex($alignItems: center);
            @include size(fit-content, 45px);
            color: $color-text;
            background-color: $color-main;
            border-radius: 15px;
            padding: 0 20px;
            margin: -60px 0 0 0;
            text-transform: uppercase;
            font-family: $font-family--heading;
            font-weight: $font-weight-semibold;
        }
    }

    &__button {
        &:after {
            background-color: $color-main;
        }
    }
}

//======================================================================================================
// Contacts
//======================================================================================================

.contacts {

    &__section {
        position: relative;
        z-index: 0;
        padding: 90px 0 0;
    }
    
    &__shape {
        z-index: -1;
        @include absolute($top :0, $right: 0);
        @include size(355px, 515px);
        fill: $color-second;
        pointer-events: none;
    }

    &__title {
        padding: 0;

        &:before {
            content: none;
        }
    }

    &__container {
        @include flex($justifyContent: space-between);
    }

    &__list {
        @include flex($alignItems: center, $justifyContent: space-between);
        @include size(790px, 250px);
        gap: 20px;
    }

    &__item {
        &__link {
            position: relative;
            @include flex($direction: column, $alignItems: flex-start, $justifyContent: flex-end);
            @include size(250px);
            background-color: $color-fourth;
            border-radius: $border-radius;
            overflow: hidden;

            &--asso {
                .contacts__item__picto {
                    @include size(50px, 55px);
                }
            }

            &--pro {
                .contacts__item__picto {
                    @include size(60px, 50px);
                }
            }

            &--serv {
                .contacts__item__picto {
                    @include size(52px);
                }
            }

            &:hover, &:focus {
                .contacts__item {

                    &__text {
                        padding-bottom: 40px;
                    }
                    
                    &__plus {
                        transform: rotate(90deg);
                        padding: 15px 15px 10px 10px;
                        background-color: $color-second;
                    }
                }
            }
        }

        &__picto {
            margin-left: 25px;
        }

        &__text {
            color: $color-text;
            padding: 30px 20px 45px 25px;
            transition: padding-bottom $duration $timing;
        }

        &__title {
            font-size: toRem(24);
            line-height: toRem(28);
            font-weight: $font-weight-extrabold;
            margin: 0 0 5px;
        }

        &__intro {
            font-size: toRem(16);
            line-height: toRem(22);
            font-weight: $font-weight-medium;
        }

        &__plus {
            @include absolute($right: -9px, $bottom: -9px);
            @include size(50px);
            padding: 10px 15px 15px 10px;
            background-color: $color-white;
            border-radius: $border-radius--round;
            box-sizing: border-box;
            transform: rotate(0);
            transition: {
                property: transform, padding, background-color;
                duration: $duration;
                timing-function: $timing;
            }
        }
    }

    &__bloc {
        @include flex($direction: column, $justifyContent: center);
        @include size(350px, 250px);
        padding: 0 30px;
        background-color: $color-white;
        border-radius: $border-radius;
        box-shadow: $shadow;

        p > span {
            font-weight: $font-weight-bold;
        }
    }

    &__button {
        width: fit-content;
    }
}


//======================================================================================================
// Discover
//======================================================================================================
.discover {

    &__section {
        padding-top: 180px;
    }

    &__container {
        @include flex($direction: row, $justifyContent: space-between);
        position: relative;
    }

    &__header {
        position: sticky;
        top: 180px;
        left: 0;
        @include flex($direction: column, $justifyContent: center);
        @include size(360px, fit-content);
        padding: 150px 0;
        
        & > p {
            font-size: toRem(18);
        }
    }

    &__title {
        position: relative;
        z-index: 0;
        @include flex($direction: column);
        line-height: toRem(55);
        padding-bottom: 20px;
        margin: 0 0 20px;

        &:before {
            background-color: $color-main;
        }

        &:after {
            content: "";
            z-index: -1;
            @include absolute($top: -25px, $left: -35px);
            @include size(90px);
            background-color: $color-main;
            border-radius: $border-radius--round;
            opacity: .5;
        }
    }

    &__points {
        @include size(850px, 532px);
        @include absolute($top: 60px, $left: 305px);
        z-index: -1;
        fill: $color-main;
        opacity: .5;
      }

    &__list {
        @include flex($direction: row, $wrap: wrap, $justifyContent: space-between);
        width: 700px;
        gap: 80px 60px;
        margin-right: 40px;
    }

    &__item {
        @include size(320px, 420px);

        &:nth-child(2n) {
            margin-top: 0;
        }

        &:nth-child(2n+1) {
            margin-top: -145px;
        }

        &:first-child {
            margin-top: 0;
        }

        &:nth-child(2) {
            margin-top: 120px;
        }

        &__link {
            position: relative;
            display: inline-block;
            @include size(320px, 420px);
            border-radius: $border-radius;
            overflow: hidden;

            &:before {
                content: '';
                @include absolute($top: 0, $right: 0, $bottom: 0, $left: 0);
                z-index: 1;
                margin: auto;
                background: linear-gradient(180deg, rgba(0, 0, 0, 0) 53.5%, rgba(0, 0, 0, 0.5) 100%);

            }

            img {
                @include size(100%);
                object-fit: cover;
                object-position: top;
                transform: scale(1);
                transition: transform $duration $timing;
            }

            svg {
                @include absolute($top: 10px, $right: 10px);
                @include size(26px);
                fill: $color-text;
                background-color: $color-white;
                border-radius: $border-radius--round;
            }

            &:hover, &:focus {
                img {
                    transform: scale(1.05);
                }
            }
        }

        &__title {
            @include absolute($bottom: 10px, $right: 10px, $left: 10px);
            z-index: 2;
            border-radius: 10px;
            font-size: toRem(16);
            line-height: toRem(21);
            font-weight: $font-weight-semibold;
            font-family: $font-family;
            color: $color-white;
            background-color: rgba($color-dark, .8);
            padding: 10px 15px;
            width: fit-content;
            margin: 0;
        }
    }
}


//======================================================================================================
// Kiosque
//======================================================================================================
.kiosque {

    &__section {
        position: relative;
        background-color: $color-fourth;
        padding: 90px 0;
        margin-top: 110px;
    }

    &__shape {
        @include position(absolute, $bottom: 0, $right: 0);
        @include size(324px, 410px);
        fill: $color-white;
    }

    &__head-title {
        @include absolute($top: 0, $left: 390px);
        z-index: 1;
    }

    &__navigation {
        @include absolute($top: 0, $bottom: 0, $left: 15px);
        margin: auto 0;
    }

    &__container {
        position: relative;
        @include flex( $alignItems: center, $justifyContent: flex-end);
    }

    &__swiper {
        width: calc(100% - 90px);

        .swiper-slide {
            @include flex($alignItems: flex-end);
            column-gap: 65px;
        }
    }

    &__content {
        margin-bottom: 75px;
        background-color: $color-fourth;
    }

    &__infos {
        display: flex;
        column-gap: 15px;
        padding-bottom: 20px;
        
        &__item {
            @include flex($alignItems: center);
            color: $color-gray;
        }

        svg {
            @include size(22px);
            fill: $color-gray;
            margin-right: 5px;
        }
    }

    &__image {
        @include size(220px, 310px);
        border-radius: 5px;
        overflow: hidden;
    }

    &__title {
        margin: 0 0 12px;
    }

    &__buttons {
        display: flex;
        column-gap: 40px;
    }

    &__button {
        @include flex($alignItems: center);
        color: $color-text;
        text-transform: uppercase;
        font-weight: $font-weight-bold;

        svg {
            @include size(30px);
            fill: $color-text;
            padding: 5px;
            margin-right: 5px;
            border-radius: $border-radius--round;
            background-color: $color-second;
            transition: {
                property: background-color, fill;
                duration: $duration;
                timing-function: $timing;
            }
        }

        &:hover, &:focus {
            color: $color-text;

            svg {
                fill: $color-white;
                background-color: $color-dark;
            }
        }
    }

    &__allbutton {
        position: absolute;
        z-index: 1;
        left: 390px;
        bottom: 0;
        margin: 0;
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {

    .home {

        &__title {
            margin: 0 0 30px;
        }
    }

    // searchbar + access
    .searchaccess {

        &__section {
            height: 400px;

            .forme-header {
                @include size(133px, 401px);
            }

            .points-header {
                top: -10px;
                left: calc(50% - 565px);
                @include size(1200px, 420px);
                clip-path: polygon(0 10px,100% 10px,100% 410px,0 410px);
            }
        }

        .searchbar-home {

            &__title {
                font-size: toRem(26);
                line-height: toRem(30);
            }
            &__bar {
                width: 400px;
            }
        }

        .access {
            &__list {
                width: 450px;
            }
        }
    }

    // Posts
    .posts {
        &__section {
            margin-top: 80px;
        }

        &__item {

            &--0 {
                height: 275px;

                .posts {

                    &__item {

                        &__content {
                            width: 400px;
                            margin-right: 40px;
                        }
                    }
                }
            }

            &__container-img {
                width: 500px;

                svg {
                    @include size(50px, 275px);
                    right: -1px;
                }
            }

            &__content {
                width: 279px;
            }
        }

        &__list-small {
            width: 880px;
        }

        &__button {
            margin-top: 30px;
        }
    }

    // Events
    .events {

        &__section {
            margin-top: 140px;
            padding-bottom: 60px;

            &:before {
                height: 330px;
            }
        }

        & .swiper-slide {
            height: 320px;
        }

        &__item {
            @include size(300px, 320px);

            &__container-img {
                @include size(300px, 220px);
            }

            &__title {
                font-size: toRem(22);
                line-height: toRem(28);
            }
        }
    }

    // Contacts
    .contacts {

        &__shape {
            @include size(300px, 435px);
        }

        &__section {
            padding-top: 80px;
        }

        &__list {
            gap: 15px;
            height: 230px;
            width: 600px;
        }

        &__item {
            &__link {
                @include size(190px, 230px);
            }

            &__picto {
                margin-left: 20px;
            }

            &__text {
                padding: 20px 20px 55px;
            }

            &__title {
                font-size: toRem(18);
                line-height: toRem(22);
                margin: 0;
            }

            &__intro {
                font-size: toRem(14);
                line-height: toRem(22);
            }
        }

        &__bloc {
            @include size(320px, 230px);
            padding: 0 20px;
        }

        &__button {
            padding: 15px;
        }
    }

    // Discover
    .discover {

        &__points {
            @include size(686px, 424px);
            top: 30px;
            left: 252px;
        }

        &__header {
            width: 270px;
            padding: 100px 0 20px;

            & > p {
                width: 250px;
            }            
        }

        &__title {
            &:after {
                @include size(75px);
                top: -10px;
                left: -25px;
            }
        }

        &__list {
            width: 610px;
            margin-right: 0;
            gap: 60px 50px;
        }

        &__item {
            @include size(280px, 380px);

            &:nth-child(2n+1) {
                margin-top: -120px;
            }

            &__link {
                @include size(280px, 380px);
            }
        }
    }

    // Kiosque
    .kiosque {

        &__shape {
            @include size(255px, 323px);
        }

        &__navigation {
            left: 10px;
        }

        &__head-title {
            left: 330px;
        }

        &__swiper {
            width: calc(100% - 70px);

            .swiper-slide {
                column-gap: 30px;
            }
        }

        &__allbutton {
            left: 330px;
        }
    }
}


// 960
@media screen and (max-width: $medium) {

    // searchbar + access
    .searchaccess {

        &__section {
            height: 525px;
            background-color: $color-third;
            overflow: hidden;

            &:before, &:has(> img)::before {
                content: none;
            }
    
            img, .forme-header {
                display: none;
            }

            .points-header {
                @include size(954px, 334px);
                top: -27px;
                left: calc(50% - 215px);
            }
        }

        &__container {
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }

        .searchbar-home {
            z-index: 1;
            padding-bottom: 30px;

            &__title {
                text-align: center;
                color: $color-text;
            }

            &__bar {
                .searchResult {
                    &.showX {
                        li {
                            display: block;
                        }
                    }
                }
            }
        }

        .access {
            @include flex($direction: row, $justifyContent: center);
            width: 100%;

            &__list {
                width: 100%;
                max-width: 450px;
            }
        }
    }

    // Posts
    .posts {

        &__container {
            position: relative;
        }

        &__item {

            &--0 {
                padding-right: 30px;

                .posts {
                    &__item {
                        &__content {
                            width: calc(50% - 30px);
                            margin-left: -10px;
                            margin-right: 0;
                        }

                        &__title {
                            font-size: toRem(26);
                            line-height: toRem(30);
                            margin-bottom: 15px;
                        }
                    }
                }

                &:hover, &:focus {
                    .posts {
                        &__item {
                            &__content {
                                margin: 0 10px 0 -10px;
                            }
                        }
                    }
                }
            }

            &__container-img {
                width: calc(50% + 20px);
            }

            &__content {
                width: 100%;
            }
        }

        &__list-small {
            width: 100%;
        }
        
        &__navigation {
            @include absolute($bottom: 0, $right: 10px);
            @include size(95px, 40px);
            flex-direction: row;
        }
    }

    // Events
    .events {

        &__section {
            &:before {
                height: 390px;
            }
        }

        &__header {
            flex-direction: column;
            align-items: flex-start;
            margin-bottom: 30px;
        }

        &__title {
            margin-bottom: 20px;
        }

        &__item {
            width: 100%;

            &__container-img {
                width: 100%;

                svg {
                    height: auto;
                    width: 100%;
                    bottom: -1px;
                }
            }
        }
    }

    // Contacts
    .contacts {

        &__shape {
            @include size(208px, 302px);
        }
        
        &__container {
            position: relative;
            flex-direction: column;
        }

        &__list {
            @include size(100%, 415px);
            flex-wrap: wrap
        }

        &__item {
            @include size(calc(50% - 7.5px), 200px);
            
            &__link {
                @include size(100%, 200px);
            }

            &__text {
                padding: 20px 40px 30px 20px;
            }
        }

        &__bloc {
            @include absolute($bottom: 0, $right: 0);
            @include size(calc(50% - 7.5px), 200px);
        }
    }

    // Discover
    .discover {

        &__section {
            padding-top: 220px;
            padding-bottom: 0;
            overflow: hidden;
        }

        &__container {
            flex-direction: column;
        }

        &__header {
            position: initial;
            width: 100%;
            padding: 0 0 40px;

            > p {
                width: 100%;
            }
        }

        &__points {
            display: none;
        }

        &__title {
            flex-direction: row;

            > span {
                margin-right: 12px;
            }
        }

        &__list {
            width: 100%;
            gap: 50px 60px;
        }

        &__item {
            @include size(calc(50% - 30px), 400px);

            &:nth-child(2n+1) {
                margin-top: -80px;
            }

            &:first-child {
                margin-top: 0;
            }

            &:nth-child(2) {
                margin-top: 70px
            }
        
            &__link {
                @include size(100%, 400px);
            }
        }
    }

    // Kiosque
    .kiosque {
        &__section {
            margin-top: 90px;
            padding-top: 70px;
        }

        &__shape {
            @include size(200px, 255px);
        }

        &__navigation {
            top: 100px;
            bottom: unset;
            @include size(95px, 40px);
            flex-direction: row;
        }

        &__container {
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
        }

        &__head-title {
            position: relative;
            margin-bottom: 100px;
            left: 0;
        }

        &__swiper {
            width: 100%;
        }

        &__buttons {
            column-gap: 15px;
        }

        &__button {
            font-size: 0;
            line-height: 0;

            svg {
                margin: 0;
            }
        }

        &__allbutton {
            position: relative;
            margin-top: 30px;
            left: 0;
        }
    }
}


// 640
@media screen and (max-width: $small) {

    .home {
        &__title {

            line-height: toRem(45);

            & > span {
                display: block;
            }
        }
    }

    // searchbar + access
    .searchaccess {

        .searchbar-home {
            @include flex($direction: column, $alignItems: center);

            &__title {
                width: 240px;
            }

            &__bar {
                width: 300px;
            }
        }
    }

    // Posts
    .posts {

        &__item {

            &--0 {
                flex-direction: column;
                height: auto;
                padding: 0;
                margin-bottom: 25px;

                .posts {

                    &__item {
                        &__content {
                            padding: 0 20px 30px;
                            margin: 0;
                            width: 100%;
                        }
                    }
                }

                &:hover, &:focus {
                    .posts {
                        &__item {
                            &__content {
                                margin: 0;
                            }
                        }
                    }
                }
            }

            &__container-img {
                @include size(100%, 200px);
                margin-bottom: 25px;
                border-radius: $border-radius;
                overflow: hidden;

                svg {
                    display: none;
                }
            }
        }

        &__button {
            margin-top: 80px;
        }

        &__navigation {
            left: 10px;
            right: unset;
            bottom: 65px;
        }
    }

    // Events
    .events {
        &__section {
            margin-top: 110px;
        }

        &__item {
            &__container-img {
                svg {
                    width: 100%;
                    height: auto;
                    bottom: -1px;
                }
            }
        }
    }

    // Contacts
    .contacts {

        &__shape {
            @include size(117px, 170px);
        }

        &__list {
            height: auto;
            flex-direction: column;
            margin-bottom: 15px;
        }

        &__item {
            width: 100%;
        }

        &__bloc {
            position: initial;
            @include size(100%, 224px);
        }

        &__button {
            height: 45px;
            padding: 10px;
        }
    }

    // Discover
    .discover {

        &__section {
            padding-top: 105px;
            padding-bottom: 0;
        }

        &__title {
            flex-direction: column;                
        }

        &__list {
            flex-direction: column;
            gap: 30px 0;
        }

        &__item {
            @include size(100%, 380px);
            margin: 0!important;

            &__link {
                @include size(100%, 380px);
                margin: 0;
            }
        }

    }

    // Kiosque
    .kiosque {

        &__head-title {
            & > span {
                display: inline;
            }
        }

        &__shape {
            @include size(145px, 185px);
        }

        &__swiper {
            .swiper-slide {
                flex-direction: column;
                align-items: flex-start;
            }
        }

        &__content {
            width: 100%;
            margin: 25px 0 0;
        }
    }
}

// 540
@media screen and (max-width: 523px) {

    // searchbar + access
    .searchaccess {
        &__section {
            height: 660px;
        }

        .access {
            &__list {
                max-width: 300px;
                height: 420px;
                grid-template-columns: 1fr 1fr;
            }
        }
    }

}

@media screen and (max-width: 360px) {
    // Contacts
    .contacts {
        &__title {
            width: 290px;
        }
    }
}