.footer {
    padding: 90px 0 50px;
    background-color: $color-dark;

    &__container {
        @include flex($wrap: wrap, $alignItems: flex-start);
        gap: 60px;
        padding-bottom: 50px;
    }

    &__logo {
        margin-top: 6px;
        svg {
            @include size(200px, 123px);
            fill: $color-white;
        }
    }

    &__infos,
    &__opening-hours {
        @include flex($direction: column, $justifyContent: center,$alignItems: flex-start);
        gap: 10px;
        flex: 1;
        color: $color-white;
    }

    &__infos {
        max-width: 281px;
        margin-left: 30px;
    }

    &__opening-hours {
        max-width: 245px;
        margin-right: 40px;
    }

    &__title {
        color: $color-white;
        font-size: toRem(20);
        line-height: toRem(28);
        font-weight: $font-weight-bold;
    }

    &__phone {
        @include flex($alignItems: center);
        gap: 5px;
        color: $color-white;

        svg {
            @include size(26px);
            fill: $color-white;
        }

        &:hover, &:focus {
            color: $color-white;
            text-decoration: underline;
        }
    }

    &__contacts {

        .footer__btn {
            color: $color-text;
            background-color: $color-second;
            margin-top: 0;
    
            svg {
                fill: $color-text;
            }
    
            &:after {
                background-color: $color-white;
            }
    
            &:hover, &:focus {
                background-color: $color-second;
            }
        }

        &__rs {
            @include flex($direction: row, $justifyContent: center);
            column-gap: 15px;
            margin: 14px 0 30px;
        
            .footer__btn {
                @include size(40px);
                border: 0;
                margin: 0;
                padding: 0;
                background-color: $color-main!important;
                border-radius: $border-radius--round;
        
                svg {
                    fill: $color-text!important;
                }
        
                &:hover, &:focus {
                    background-color: $color-third!important;
        
                    svg {
                        fill: $color-text!important;
                    }
                }
            }
        }
    }

    &__btn--contact {
        min-width: 205px;

        &:hover, &:focus {
            &:after {
                right: -20px !important;
            }
        }
    }

    &__menu {

        &__container {
            @include flex($direction: column, $justifyContent: center);
        }

        &__list {
            @include flex($wrap: wrap, $alignItems: center, $justifyContent: space-between);
            width: 100%;
            padding: 40px 0;
        }

        &__item {
            position: relative;
        }

        &__link {
            color: $color-white;
            transition: opacity $duration $timing;

            &:hover, &:focus {
                color: $color-white;
                opacity: .6;
            }
        } 
    }

    &__labels {
        @include flex($alignItems: center, $justifyContent: center);
        column-gap: 30px;
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {

    .footer {
        padding: 60px 0 50px;

        &__container {
            column-gap: 22px;
            padding-bottom: 40px;
        }

        &__logo {
            margin-top: 14px;

            svg {
                @include size(160px, 100px);
            }
        }

        &__infos {
            margin-top: 7px;
        }

        &__infos, &__opening-hours {
            margin: 0;
        }
 
        &__menu {
            &__link {
                font-size: toRem(14);
            }
        }
    }

}


// 960
@media screen and (max-width: $medium) {

    .footer {
        padding: 45px 0 110px;

        &__container {
            flex-direction: column;
            align-items: center;
            padding-bottom: 25px;
            gap: 30px 0;
        }

        &__logo, &__contacts {
            margin: 0;
        }
        
        &__infos, &__opening-hours {
            text-align: center;
            align-items: center;
        }
        
        &__menu {

            &__list {
                padding: 15px 25px;
                justify-content: center;
                gap: 20px 30px;
            }
        }
    }
}


// 640
@media screen and (max-width: $small) {

    .footer {

        &__menu {
            &__list {
                gap: 20px;
                text-align: center;
            }
        }

        &__labels {
            flex-wrap: wrap;
            gap: 10px 20px;
        }
    }
}
