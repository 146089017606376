.calculator {
  width: 50%;
  min-width: 580px;

  .field-calculator_revenu label{
    font-weight: 700;
    margin-bottom: 5px;
  }
  .field-calculator_commune{
    .acf-radio-list {
      display: flex;

      li{
        margin: 0 20px 0 0;

        label{
          font-weight: $font-weight;
        }
      }
    }
  }

  #calculatorBtn {
    margin: 30px 0 20px;
  }
}
.exterieur{
  .no-ext{
    display: none!important;
  }
}

#calculator_prestation {
  display: flex;
  flex-direction: column;
  width: 50%;
  min-width: 580px;
  margin: 0 0 30px;

  li {
    display: flex;
    justify-content: space-between;
    padding: 15px;
    margin: 5px 0;
    box-sizing: border-box;
    background: $color-fourth;
    border-radius: $border-radius;

    &[data-value="j-loisirs"],
    &[data-value="accueil-matin"],
    &[data-value="restauration-maternelle"]{
      margin: 25px 0 5px;
    }

    .prestation_label {
      font-weight: $font-weight-bold;
    }
  }
}


// 640
@media screen and (max-width: $small) {
  .calculator {
    width: 100%;
    min-width: auto;
  }

  #calculator_prestation{
    width: 100%;
    min-width: 0;
    max-width: 320px;
  }
}
