.card {
    //=================================================================================================
    // Image par défaut
    //=================================================================================================
    .image-wrapper {
        &.contacts,
        &.contacts_category {
            background-image: url($urlSpriteImage + '/contacts-icons/contacts.svg');
        }
    }
}