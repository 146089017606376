.cover {
    position: relative;
    @include flex($direction: column, $wrap: wrap);
    z-index: 0;
    background-color: $color-third;
    padding: 0 0 40px;

    &__container {
        position: relative;
        @include flex($direction: row, $alignItems: center, $justifyContent: space-between);
        width: 100%;
        z-index: 1;
    }

    &__arc-toppost {
        z-index: 0;
        @include absolute($bottom: 0, $left: 0);
        @include size(200px, 219px);
        fill: $color-main;
        opacity: .5;
        pointer-events: none;
    }

    &__image-wrapper {
        @include size(calc(50% - 40px), 320px);
        background-color: $color-bg--image;
        border-radius: $border-radius;
        overflow: hidden;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: top;
        }

        & ~ .cover__informations {
            width: 50%;
        }
    }

    &__informations {
        width: 100%;
        padding: 0;
    }

    &__title {
        font-size: toRem(45);
        line-height: toRem(50);
        font-weight: $font-weight-extrabold;
        margin: 0 0 10px;
    }

    &__lead-wrapper {
        p {
            margin: 20px 0 0;
            font-weight: $font-weight-bold;
            font-size: $font-size--5;
        }
    }

    &__intro {
        color: $color-text;
    }
}

.search .cover {
    padding-top: 65px;
}


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {

    .cover {
        &__image-wrapper {
            @include size(calc(50% - 30px), 255px);
        }
    }

}

// 960
@media screen and (max-width: $medium) {

    .cover {

        padding: 30px 0 0;

        &__arc-toppost {
            display: none;
        }

        &__container {
            flex-direction: column
        }

        &__image-wrapper {
            @include size(100%, 350px);
            margin-bottom: 30px;

            & ~ .cover__informations {
                width: 100%;
            }
        }

        &__informations {
            width: 100%;
        }

        &__title {
            font-size: toRem(36);
            line-height: toRem(40);
        }
    }

    .search .cover {
        padding: 30px 0;
    }
}

// 640
@media screen and (max-width: $small) {

    .cover {        
        &__image-wrapper {
            height: 200px;
        }
    }

}
