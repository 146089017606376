header {
    position: relative;
    width: 100%;
    z-index: 5;
    background-color: $color-white;
    box-shadow: $shadow;
    transition: top .5s $timing;

    &.header {

        &--fixed {
            position: fixed;

            & ~ main {
                padding-top: 130px;
            }
        }
        
        &--up {
            top: 0;
        }
 
        &--down {
            top: -130px;
        }
    }

    .header {

        &__container {
            @include size(100%, 130px);
            @include flex($alignItems: center, $justifyContent: space-between);
        }

        &__right {
            @include flex($alignItems: center);
            gap: 50px;
        }
    }
}

//======================================================================================================
// Identity
//======================================================================================================
.identity {
    &__title {
        @include flex($alignItems: center, $justifyContent: center);
        @include size(268px, 95px);
        padding: 0;
        margin: 5px 0 0;
        transition: color $duration $timing;

        &:before {
            content: none;
        }
    }
}

//======================================================================================================
// Sticky
//======================================================================================================
.sticky {
    z-index: 5;
    @include fixed($top: 515px, $right: -123px);
    @include flex($direction: row, $alignItems: center, $justifyContent: space-between);
    @include size(193px, 70px);
    cursor: pointer;
    background-color: $color-second;
    border-radius: $border-radius 0 0 $border-radius;
    padding: 11px 16px 11px 20px;
    box-shadow: $shadow;
    transition: right $duration $timing;

    svg {
        @include size(43px, 48px);
        fill: $color-text;
        margin-right: 9px;
    }

    &-header {
        font-size: toRem(16);
        line-height: toRem(20);
        font-weight: $font-weight-bold;
        width: 105px;
        opacity: 0;
        transition: opacity $duration $timing;
    }

    &--open, &:hover, &:focus {
        right: 0;
        background-color: $color-second;

        .sticky-header {
            opacity: 1;
        }
    }

    &__close {
        @include size(30px);
        padding: 0;
        margin: 0 0 -22px auto;
        border-radius: $border-radius--round;
        
        svg {
            @include size(11px);
        }
    }

    &__list {
        z-index: 5;
        @include fixed($top: 340px, $right: -300px);
        @include size(285px, auto);
        background-color: $color-white;
        border-radius: $border-radius 0 0 $border-radius;
        @include flex($direction: column, $alignItems: flex-start, $justifyContent: flex-start);
        column-gap: 5px 0;
        padding: 20px 35px 20px 20px;
        box-shadow: $shadow;
        overflow: hidden;
        opacity: 0;
        transition: {
            property: right, bottom, opacity;
            duration: $duration;
            timing-function: $timing;
        }

        &--active {
            opacity: 1;
            right: 0;
        }
    }

    &__link {
        @include flex($direction: row, $alignItems: center);
        column-gap: 5px;
        color: $color-text;
        transition: column-gap $duration $timing;

        svg {
            @include size(20px);
        }

        &:hover, &:focus {
            color: $color-text;
            column-gap: 10px;
            text-decoration: underline;
        }
    }
}


//======================================================================================================
// Tools
//======================================================================================================

.tools {

    &__container {
        display: flex;
        justify-content: flex-end;
    }

    &__content {
        position: relative;
        gap: 10px;
        z-index: 0;
        display: flex;
        justify-content: center;
        background-color: $color-bg--transparent;
        pointer-events: auto;
    }

    &__item {
        @include flex($justifyContent: center, $alignItems: center);
        @include size(40px);
        padding: 0;
        margin: 0;
        background-color: $color-second;
        border: 0;
        border-radius: $border-radius--round;
        cursor: pointer;

        svg {
            @include size(30px);
            fill: $color-text;
            transition: fill $duration $timing;
        }

        &:hover, &:focus {
            svg {
                fill: $color-white;
            }
        }

        &--search {

            &--disabled {
                pointer-events: none;
                opacity: .2;
            }
        }
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {
    header {
        
        & .header {
            &__right {
                gap: 30px;
            }

            &__container {
                height: 110px;
                justify-content: center;
            }
        }

        .identity {
            .identity__title {
                svg {
                    @include size(220px, 79px);
                }
            }
        }
    }

    .sticky {
        top: 475px;

        &__list {
            top: 290px;
        }
    }
}


// 960
@media screen and (max-width: $medium) {
    
header {

    &.header {

        &--fixed {
            position: initial;

            & ~ main {
                padding-top: 0;
            }
        }
    }

    & .header {
        &__right {
            position: fixed;
            z-index: 5;
            left: 0;
            right: 0;
            bottom: 0;
            margin: 0 auto;
            padding: 0 calc(5% + 10px);
            gap: 15px;
            justify-content: flex-start;
            background-color: $color-white;
            box-shadow: $shadow;
        }
    }
}
    
    // Identity
    .identity {
        display: flex;
        min-height: 90px;
        height: auto;
    }

    // Tools
    .tools {
        margin: 15px 0;
    }

    // Sticky
    .sticky {
        top: unset;
        z-index: 5;
        bottom: 0;
        @include size(100px, 80px);
        right: calc(5% + 10px);
        flex-direction: column;
        align-items: center;
        border-radius: $border-radius $border-radius 0 0;

        svg {
            @include size(32px, 35px);
        }

        .sticky-header {
            font-size: toRem(12);
            line-height: toRem(13);
            text-align: center;
            opacity: 1;
        }

        &:hover, &:focus {
            right: calc(5% + 10px);
        }

        &__list {
            z-index: 5;
            border-radius: $border-radius $border-radius 0 0;
            padding: 20px 20px 35px 20px;
            top: unset;
            bottom: -360px;
            right: calc(5% + 10px);
            
            &--active {
                right: calc(5% + 10px);
                bottom: 0;
                right: calc(5% + 10px);
            }
        }
    }
}


// 640
@media screen and (max-width: $small) {

    .sticky {
        &__list {
            width: 100%;
            right: 0;
        }
    }

    .tools {
        &__content {
            gap: 5px;
        }
    }

}
